import * as React from 'react';
import { Form, Button, Input, InputNumber, DatePicker } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import IProperty from '../../model/IProperty';
import moment from 'moment';
import 'moment/locale/de';

moment.locale('de');

export interface IProps extends FormComponentProps {
    property: IProperty;
    onSubmit: (data: IProperty) => void;
    submitLabel: string;
}

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Property extends React.Component<IProps, {}> {
    public render() {
        const {getFieldDecorator, getFieldsError, getFieldError, setFieldsValue} = this.props.form;

        const propertyCityError = getFieldError('propertyCity');
        const propertyPriceError = getFieldError('propertyPrice');
        const notaryError = getFieldError('notary');
        const conveyanceDutyError = getFieldError('conveyanceDuty');
        const brokersCommissionError = getFieldError('brokersCommission');
        const conditionalContractPropertyError = getFieldError('conditionalContractProperty');
        const conditionalContractCreditError = getFieldError('conditionalContractCredit');
        const conditionalContractBEGError = getFieldError('conditionalContractBEG');

        return (
            <Form onSubmit={this.onSubmit.bind(this)}>
                <Form.Item
                    validateStatus={propertyCityError ? 'error' : 'success'}
                    help={propertyCityError || ''}
                    label="Bauort"
                >
                    {getFieldDecorator('propertyCity', {
                        initialValue: this.props.property.propertyCity,
                        rules: [{required: true, message: 'Bitte Bauort eingeben.'}],

                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    propertyCity: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={propertyPriceError ? 'error' : 'success'}
                    help={propertyPriceError || ''}
                    label="Kaufpreis des Grundstückes"
                >
                    {getFieldDecorator('propertyPrice', {
                        initialValue: this.props.property.propertyPrice,
                        rules: [{required: true, message: 'Bitte den Kaufpreis des Grundstückes eingeben.'}],

                    })(
                        <InputNumber
                            onChange={(propertyPrice) => {
                                setFieldsValue({
                                    propertyPrice
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={notaryError ? 'error' : 'success'}
                    help={notaryError || ''}
                    label="Notar und Grundbuch"
                >
                    {getFieldDecorator('notary', {
                        initialValue: this.props.property.notary,
                        rules: [{required: true, message: 'Bitte Notar und Grundbuch eingeben.'}],
                    })(
                        <InputNumber
                            min={0}
                            max={100}
                            onChange={(notary) => {
                                setFieldsValue({
                                    notary
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={conveyanceDutyError ? 'error' : 'success'}
                    help={conveyanceDutyError || ''}
                    label="Grunderwerbssteuer"
                >
                    {getFieldDecorator('conveyanceDuty', {
                        initialValue: this.props.property.conveyanceDuty,
                        rules: [{required: true, message: 'Bitte Grunderwerbssteuer eingeben.'}],
                    })(
                        <InputNumber
                            min={0}
                            max={100}
                            onChange={(conveyanceDuty) => {
                                setFieldsValue({
                                    conveyanceDuty
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={brokersCommissionError ? 'error' : 'success'}
                    help={brokersCommissionError || ''}
                    label="Maklerprovision"
                >
                    {getFieldDecorator('brokersCommission', {
                        initialValue: this.props.property.brokersCommission,
                        rules: [{required: true, message: 'Bitte Maklerprovision eingeben.'}],
                    })(
                        <InputNumber
                            min={0}
                            max={100}
                            onChange={(brokersCommission) => {
                                setFieldsValue({
                                    brokersCommission
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={conditionalContractPropertyError ? 'error' : 'success'}
                    help={conditionalContractPropertyError || ''}
                    label="Vertragsvorbehalt Grundstück"
                >
                    {getFieldDecorator('conditionalContractProperty', {
                        initialValue: this.props.property.conditionalContractProperty ? moment.unix(this.props.property.conditionalContractProperty) : null
                    })(
                        <DatePicker
                            placeholder="Datum"
                            onChange={(date) => {
                                setFieldsValue({
                                    conditionalContractProperty: date
                                })
                            }}
                            format="DD.MM.YYYY"
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={conditionalContractCreditError ? 'error' : 'success'}
                    help={conditionalContractCreditError || ''}
                    label="Vertragsvorbehalt Finanzierung"
                >
                    {getFieldDecorator('conditionalContractCredit', {
                        initialValue: this.props.property.conditionalContractCredit ? moment.unix(this.props.property.conditionalContractCredit) : null
                    })(
                        <DatePicker
                            placeholder="Datum"
                            onChange={(date) => {
                                setFieldsValue({
                                    conditionalContractCredit: date
                                })
                            }}
                            format="DD.MM.YYYY"
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={conditionalContractBEGError ? 'error' : 'success'}
                    help={conditionalContractBEGError || ''}
                    label="Vertragsvorbehalt Förderung BEG"
                >
                    {getFieldDecorator('conditionalContractBEG', {
                        initialValue: this.props.property.conditionalContractBEG ? moment.unix(this.props.property.conditionalContractBEG) : null
                    })(
                        <DatePicker
                            placeholder="Datum"
                            onChange={(date) => {
                                setFieldsValue({
                                    conditionalContractBEG: date
                                })
                            }}
                            format="DD.MM.YYYY"
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={hasErrors(getFieldsError())}
                    >
                        {this.props.submitLabel}
                    </Button>
                </Form.Item>
            </Form>
        );
    }

    protected onSubmit(e: React.FormEvent<any>): void {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit({
                    propertyCity: values.propertyCity,
                    propertyPrice: values.propertyPrice,
                    notary: values.notary,
                    conveyanceDuty: values.conveyanceDuty,
                    brokersCommission: values.brokersCommission,
                    conditionalContractProperty: values.conditionalContractProperty ? (values.conditionalContractProperty as any).unix() : null,
                    conditionalContractCredit: values.conditionalContractCredit ? (values.conditionalContractCredit as any).unix() : null,
                    conditionalContractBEG: values.conditionalContractBEG ? (values.conditionalContractBEG as any).unix() : null,
                });
            }
        });
    }
}

export default Form.create()(Property);