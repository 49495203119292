import * as React from 'react';
import { Form, Button, Input, DatePicker, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import ICustomer from '../../model/ICustomer';
import moment from 'moment';
import 'moment/locale/de';

moment.locale('de');

export interface IProps extends FormComponentProps {
    customer: ICustomer;
    onSubmit: (data: ICustomer) => void;
    submitLabel: string;
}

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Customer extends React.Component<IProps, {}> {
    public render() {
        const {getFieldDecorator, getFieldsError, getFieldError, setFieldsValue} = this.props.form;

        const nameError = getFieldError('name');
        const salutation1Error = getFieldError('salutation1');
        const firstName1Error = getFieldError('firstName1');
        const lastName1Error = getFieldError('lastName1');
        const birthday1Error = getFieldError('birthday1');
        const city1Error = getFieldError('city1');
        const zip1Error = getFieldError('zip1');
        const street1Error = getFieldError('street1');
        const streetNumber1Error = getFieldError('streetNumber1');
        const salutation2Error = getFieldError('salutation2');
        const firstName2Error = getFieldError('firstName2');
        const lastName2Error = getFieldError('lastName2');
        const birthday2Error = getFieldError('birthday2');
        const city2Error = getFieldError('city2');
        const zip2Error = getFieldError('zip2');
        const street2Error = getFieldError('street2');
        const streetNumber2Error = getFieldError('streetNumber2');

        return (
            <Form onSubmit={this.onSubmit.bind(this)}>
                <Form.Item
                    validateStatus={nameError ? 'error' : 'success'}
                    help={nameError || ''}
                    label="Name der Konfiguration"
                >
                    {getFieldDecorator('name', {
                        initialValue: this.props.customer.name,
                        rules: [{required: true, message: 'Bitte Name der Konfiguration eingeben.'}],
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    name: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={salutation1Error ? 'error' : 'success'}
                    help={salutation1Error || ''}
                    label="Bauherr 1 Anrede"
                >
                    {getFieldDecorator('salutation1', {
                        initialValue: this.props.customer.salutation1,
                        rules: [{required: true, message: 'Bitte Anrede wählen.'}],
                    })(
                        <Select onChange={(value: string) => {
                            setFieldsValue({
                                salutation1: value
                            })
                        }}>
                            <Select.Option value="male">Herr</Select.Option>
                            <Select.Option value="female">Frau</Select.Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={firstName1Error ? 'error' : 'success'}
                    help={firstName1Error || ''}
                    label="Bauherr 1 Vorname"
                >
                    {getFieldDecorator('firstName1', {
                        initialValue: this.props.customer.firstName1,
                        rules: [{required: true, message: 'Bitte Vorname eingeben.'}],
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    firstName1: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={lastName1Error ? 'error' : 'success'}
                    help={lastName1Error || ''}
                    label="Bauherr 1 Nachname"
                >
                    {getFieldDecorator('lastName1', {
                        initialValue: this.props.customer.lastName1,
                        rules: [{required: true, message: 'Bitte Nachname eingeben.'}],
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    lastName1: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={birthday1Error ? 'error' : 'success'}
                    help={birthday1Error || ''}
                    label="Bauherr 1 Geburtstag"
                >
                    {getFieldDecorator('birthday1', {
                        initialValue: this.props.customer.birthday1 ? moment.unix(this.props.customer.birthday1) : null,
                    })(
                        <DatePicker
                            placeholder="Geburtstag"
                            onChange={(date) => {
                                setFieldsValue({
                                    birthday1: date
                                })
                            }}
                            format="DD.MM.YYYY"
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={city1Error ? 'error' : 'success'}
                    help={city1Error || ''}
                    label="Bauherr 1 Ort"
                >
                    {getFieldDecorator('city1', {
                        initialValue: this.props.customer.city1,
                        rules: [{required: true, message: 'Bitte Ort eingeben.'}],
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    city1: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={zip1Error ? 'error' : 'success'}
                    help={zip1Error || ''}
                    label="Bauherr 1 PLZ"
                >
                    {getFieldDecorator('zip1', {
                        initialValue: this.props.customer.zip1,
                        rules: [{required: true, message: 'Bitte PLZ eingeben.'}],
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    zip1: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={street1Error ? 'error' : 'success'}
                    help={street1Error || ''}
                    label="Bauherr 1 Straße"
                >
                    {getFieldDecorator('street1', {
                        initialValue: this.props.customer.street1,
                        rules: [{required: true, message: 'Bitte Straße eingeben.'}],

                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    street1: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={streetNumber1Error ? 'error' : 'success'}
                    help={streetNumber1Error || ''}
                    label="Bauherr 1 Nummer"
                >
                    {getFieldDecorator('streetNumber1', {
                        initialValue: this.props.customer.streetNumber1,
                        rules: [{required: true, message: 'Bitte Nummer eingeben.'}],
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    streetNumber1: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={salutation2Error ? 'error' : 'success'}
                    help={salutation2Error || ''}
                    label="Bauherr 2 Anrede"
                >
                    {getFieldDecorator('salutation2', {
                        initialValue: this.props.customer.salutation2
                    })(
                        <Select onChange={(value: string) => {
                            setFieldsValue({
                                salutation2: value
                            })
                        }}>
                            <Select.Option value="male">Herr</Select.Option>
                            <Select.Option value="female">Frau</Select.Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={firstName2Error ? 'error' : 'success'}
                    help={firstName2Error || ''}
                    label="Bauherr 2 Vorname"
                >
                    {getFieldDecorator('firstName2', {
                        initialValue: this.props.customer.firstName2
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    firstName2: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={lastName2Error ? 'error' : 'success'}
                    help={lastName2Error || ''}
                    label="Bauherr 2 Nachname"
                >
                    {getFieldDecorator('lastName2', {
                        initialValue: this.props.customer.lastName2,
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    lastName2: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={birthday2Error ? 'error' : 'success'}
                    help={birthday2Error || ''}
                    label="Bauherr 2 Geburtstag"
                >
                    {getFieldDecorator('birthday2', {
                        initialValue: this.props.customer.birthday2 ? moment.unix(this.props.customer.birthday2) : null
                    })(
                        <DatePicker
                            placeholder="Geburtstag"
                            onChange={(date) => {
                                setFieldsValue({
                                    birthday2: date
                                })
                            }}
                            format="DD.MM.YYYY"
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={city2Error ? 'error' : 'success'}
                    help={city2Error || ''}
                    label="Bauherr 2 Ort"
                >
                    {getFieldDecorator('city2', {
                        initialValue: this.props.customer.city2
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    city2: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={zip2Error ? 'error' : 'success'}
                    help={zip2Error || ''}
                    label="Bauherr 2 PLZ"
                >
                    {getFieldDecorator('zip2', {
                        initialValue: this.props.customer.zip2
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    zip2: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={street2Error ? 'error' : 'success'}
                    help={street2Error || ''}
                    label="Bauherr 2 Straße"
                >
                    {getFieldDecorator('street2', {
                        initialValue: this.props.customer.street2
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    street2: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={streetNumber2Error ? 'error' : 'success'}
                    help={streetNumber2Error || ''}
                    label="Bauherr 2 Nummer"
                >
                    {getFieldDecorator('streetNumber2', {
                        initialValue: this.props.customer.streetNumber2
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    streetNumber2: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={hasErrors(getFieldsError())}
                    >
                        {this.props.submitLabel}
                    </Button>
                </Form.Item>
            </Form>
        );
    }

    protected onSubmit(e: React.FormEvent<any>): void {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit({
                    name: values.name,
                    salutation1: values.salutation1,
                    firstName1: values.firstName1,
                    lastName1: values.lastName1,
                    birthday1: values.birthday1 ? (values.birthday1 as any).unix() : null,
                    city1: values.city1,
                    zip1: values.zip1,
                    street1: values.street1,
                    streetNumber1: values.streetNumber1,
                    salutation2: values.salutation2,
                    firstName2: values.firstName2,
                    lastName2: values.lastName2,
                    birthday2: values.birthday2 ? (values.birthday2 as any).unix() : null,
                    city2: values.city2,
                    zip2: values.zip2,
                    street2: values.street2,
                    streetNumber2: values.streetNumber2
                });
            }
        });
    }
}

export default Form.create()(Customer);