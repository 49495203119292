import * as React from 'react';

const logo = require('../../img/logo.png');

export interface IState {
    isMobileNavigationVisible: boolean;
}

class Header extends React.Component<{}, IState> {
    constructor(props: {}, context: {}) {
        super(props, context);

        this.state = {
            isMobileNavigationVisible: false
        };
    }

    handleClick = () => {
        this.setState({
            isMobileNavigationVisible: !this.state.isMobileNavigationVisible
        });
    }

    public render() {
        let headerClasses = ['header-container'];

        if (this.state.isMobileNavigationVisible) {
            headerClasses.push('header-containerWide');
        }

        return (
            <div id="cHeaderElement" className={headerClasses.join(' ')}>
                <header className="wrapper clearfix">
                    <div id="cLogo"><img src={logo}/></div>
                    <div id="cMobileNaviBtn" onClick={this.handleClick.bind(this)}>
                        <div id="cBtn"
                             className={this.state.isMobileNavigationVisible ? 'cNaviButtonClose' : 'cNaviButtonOpen'}></div>
                    </div>
                    <nav
                        className={this.state.isMobileNavigationVisible ? 'nav-visible' : 'nav-invisible'}>{this.props.children}</nav>
                    <div id="cHeadFooterContainer"></div>
                </header>
            </div>
        );
    }
}

export default Header;