import { ThunkAction } from 'redux-thunk';
import { types } from '../action/constants';
import IShowVisualsForm from '../action/IShowVisualsForm';

export default function showVisualsForm(show: boolean): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const showVisualsForm: IShowVisualsForm = {
                type: types.show_visuals_form,
                show
            };

            dispatch(showVisualsForm);

            resolve();
        });
    }
}