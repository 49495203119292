import { get, assign } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { types } from '../action/constants';
import IConfiguration from '../model/IConfiguration';

export default function setConfiguration(configuration: IConfiguration): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            dispatch({
                type: types.set_configuration,
                configuration: assign(
                    {},
                    get(getState(), 'configuration', {}),
                    configuration
                )
            });

            resolve();
        });
    }
}