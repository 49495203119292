import { omit, assign } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { types } from '../action/constants';
import IConfiguration from '../model/IConfiguration';
import timeout from './timeout';
import { getApiHost, getApiPort, getApiProtocol } from '../util/env';

export default function saveConfiguration(token: string, configuration: IConfiguration): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: types.set_busy,
                busy: true
            });

            let content: any = assign({}, omit(
                configuration,
                ['id', 'isDefault', 'workflowStep', 'priceList', 'scheme']
            ), {
                entities: configuration.entities.map((entity) => {
                    return entity.id
                }),
            });

            content = assign(content, {scheme: configuration.scheme.id});

            fetch(`${getApiProtocol()}://${getApiHost()}:${getApiPort()}/configuration/create`, {
                body: JSON.stringify(content),
                cache: 'no-cache',
                headers: {
                    'content-type': 'application/json',
                    'X-AUTH-TOKEN': token
                },
                method: 'POST',
                mode: 'cors',
                redirect: 'follow'
            }).then((response) => {
                if (!response.ok) {
                    throw response;
                }

                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                resolve();
            }).catch((response) => {
                if (response.status === 401) {
                    dispatch(timeout());
                }

                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                reject();
            });
        });
    }
}
