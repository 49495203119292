import * as React from 'react';
import { filter, get } from 'lodash';
import { Form, Button, InputNumber } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import IOwnPerformance from '../../model/IOwnPerformance';
import IEntity from '../../model/IEntity';

export interface IProps extends FormComponentProps {
    ownPerformance: IOwnPerformance;
    selectedEntities: IEntity[];
    onSubmit: (data: IOwnPerformance) => void;
    submitLabel: string;
}

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class OwnPerformance extends React.Component<IProps, {}> {
    public render() {
        const {getFieldDecorator, getFieldsError, getFieldError, setFieldsValue} = this.props.form;

        return (
            <Form onSubmit={this.onSubmit.bind(this)}>
                {filter(this.props.selectedEntities, (entity) => {
                    return entity.tags.indexOf('Eigenleistung') !== -1;
                }).map((entity) => {
                    let error = getFieldError(entity.id);

                    return (
                        <Form.Item
                            key={entity.id}
                            validateStatus={error ? 'error' : 'success'}
                            help={error || ''}
                            label={`${entity.name} in %`}
                        >
                            {getFieldDecorator(entity.id, {
                                initialValue: get(
                                    this.props.ownPerformance,
                                    entity.id,
                                    0
                                ),
                            })(
                                <InputNumber
                                    min={0}
                                    max={100}
                                    onChange={(value) => {
                                        setFieldsValue({
                                            [entity.id]: value
                                        })
                                    }}
                                />
                            )}
                        </Form.Item>
                    );
                })}
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={hasErrors(getFieldsError())}
                    >
                        {this.props.submitLabel}
                    </Button>
                </Form.Item>
            </Form>
        );
    }

    protected onSubmit(e: React.FormEvent<any>): void {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit(values);
            }
        });
    }
}

export default Form.create()(OwnPerformance);