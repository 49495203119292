import { ThunkAction } from 'redux-thunk';
import { types } from '../action/constants';
import IShowContractForm from '../action/IShowContractForm';

export default function showContractForm(show: boolean): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const showContractForm: IShowContractForm = {
                type: types.show_contract_form,
                show
            };

            dispatch(showContractForm);

            resolve();
        });
    }
}