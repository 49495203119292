import * as React from 'react';
import { Form, Button, Input } from 'antd';
import { assign } from 'lodash';
import { FormComponentProps } from 'antd/lib/form';
import IConfiguration from "../model/IConfiguration";

export interface IProps extends FormComponentProps {
    customer?: IConfiguration;
    onSubmit: (data: IConfiguration) => void;
    submitLabel: string;
}

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Visuals extends React.Component<IProps, {}> {
    public render() {
        const {getFieldDecorator, getFieldsError, getFieldError, setFieldsValue} = this.props.form;

        const firstName1Error = getFieldError('firstName1');
        const lastName1Error = getFieldError('lastName1');
        const firstName2Error = getFieldError('firstName2');
        const lastName2Error = getFieldError('lastName2');

        return (
            <Form onSubmit={this.onSubmit.bind(this)}>
                <Form.Item
                    validateStatus={firstName1Error ? 'error' : 'success'}
                    help={firstName1Error || ''}
                    label="Bauherr 1 Vorname"
                >
                    {getFieldDecorator('firstName1', {
                        initialValue: this.props.customer.firstName1,
                        rules: [{required: true, message: 'Bitte Vorname eingeben.'}],
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    firstName1: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={lastName1Error ? 'error' : 'success'}
                    help={lastName1Error || ''}
                    label="Bauherr 1 Nachname"
                >
                    {getFieldDecorator('lastName1', {
                        initialValue: this.props.customer.lastName1,
                        rules: [{required: true, message: 'Bitte Nachname eingeben.'}],
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    lastName1: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={firstName2Error ? 'error' : 'success'}
                    help={firstName2Error || ''}
                    label="Bauherr 2 Vorname"
                >
                    {getFieldDecorator('firstName2', {
                        initialValue: this.props.customer.firstName2
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    firstName2: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={lastName2Error ? 'error' : 'success'}
                    help={lastName2Error || ''}
                    label="Bauherr 2 Nachname"
                >
                    {getFieldDecorator('lastName2', {
                        initialValue: this.props.customer.lastName2,
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    lastName2: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={hasErrors(getFieldsError())}
                    >
                        {this.props.submitLabel}
                    </Button>
                </Form.Item>
            </Form>
        );
    }

    protected onSubmit(e: React.FormEvent<any>): void {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit(
                    assign({}, this.props.customer, {
                        firstName1: values.firstName1,
                        lastName1: values.lastName1,
                        firstName2: values.firstName2,
                        lastName2: values.lastName2
                    })
                );
            }
        });
    }
}

export default Form.create()(Visuals);