import * as React from 'react';
import { Form, Button, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import IRuleSet from '../model/api/IRuleSet';

export interface IProps extends FormComponentProps {
    ruleSetList: IRuleSet;
    onSuccess: (configId: string, priceListId: string, useDefaultConfiguration: boolean) => void;
}

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class CreateConfiguration extends React.Component<IProps, {}> {
    public render() {
        return this.props.ruleSetList.defaultConfigurations.length > 0 ?
            this.renderDefaultConfigurationSelector() : this.renderDefaultSelector();
    }

    protected renderDefaultConfigurationSelector() {
        const {getFieldDecorator, getFieldsError, getFieldError, setFieldsValue} = this.props.form;
        const configurationAndPriceListError = getFieldError('configurationAndPriceList');

        return (
            <Form onSubmit={this.onSubmitDefaultConfigurationSelector.bind(this)}>
                <Form.Item
                    validateStatus={configurationAndPriceListError ? 'error' : 'success'}
                    help={configurationAndPriceListError || ''}
                    label="Konfiguration & Preisliste"
                >
                    {getFieldDecorator('configurationAndPriceList', {
                        rules: [{required: true, message: 'Bitte Konfiguration & Preisliste auswählen.'}],

                    })(
                        <Select onChange={(value: string) => {
                            setFieldsValue({
                                configurationAndPriceList: value
                            })
                        }}>
                            {this.props.ruleSetList.defaultConfigurations.map((configuration, index) => {
                                return (
                                    <Select.OptGroup label={configuration.name} key={configuration.id}>
                                        {configuration.scheme.priceLists.map((priceList) => {
                                            return (
                                                <Select.Option
                                                    value={`${configuration.id}-${priceList.id}`}
                                                    key={`${configuration.id}-${priceList.id}`}
                                                >{priceList.name}</Select.Option>
                                            );
                                        })}
                                    </Select.OptGroup>
                                );
                            })}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={hasErrors(getFieldsError())}
                    >
                        übernehmen
                    </Button>
                </Form.Item>
            </Form>
        );
    }

    protected renderDefaultSelector() {
        const {getFieldDecorator, getFieldsError, getFieldError, setFieldsValue} = this.props.form;
        const priceListError = getFieldError('priceList');

        return (
            <Form onSubmit={this.onSubmitDefaultSelector.bind(this)}>
                <Form.Item
                    validateStatus={priceListError ? 'error' : 'success'}
                    help={priceListError || ''}
                    label="Preisliste"
                >
                    {getFieldDecorator('priceList', {
                        rules: [{required: true, message: 'Bitte Preisliste auswählen.'}],

                    })(
                        <Select onChange={(value: string) => {
                            setFieldsValue({
                                priceList: value
                            })
                        }}>
                            {this.props.ruleSetList.scheme.priceLists.map((priceList) => {
                                return (
                                    <Select.Option value={priceList.id}
                                                   key={priceList.id}>{priceList.name}</Select.Option>
                                );
                            })}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={hasErrors(getFieldsError())}
                    >
                        übernehmen
                    </Button>
                </Form.Item>
            </Form>
        );
    }

    protected onSubmitDefaultSelector(e: React.FormEvent<any>): void {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSuccess(null, values.priceList, false);
            }
        });
    }

    protected onSubmitDefaultConfigurationSelector(e: React.FormEvent<any>): void {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                const splittedValue = values.configurationAndPriceList.split('-');

                this.props.onSuccess(splittedValue[0], splittedValue[1], true);
            }
        });
    }
}

export default Form.create()(CreateConfiguration);