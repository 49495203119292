import * as React from 'react';
import Header from './layout/Header';
import Content from './layout/Content';
import Footer from './layout/Footer';
import Selector from './Selector';
import Login from './Login';
import Settings from './Settings';
import Summary from './Summary';
import ConfigurationForm from './ConfigurationForm';
import Visuals from './Visuals';
import { Modal, notification, Button } from 'antd';
import CreateConfiguration from './CreateConfiguration';
import LoadConfiguration from './LoadConfiguration';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { isStepFulfilled } from '../util/configuration';
import logoutCreator from '../creator/logout';
import IConfiguration from '../model/IConfiguration';
import loginCreator from '../creator/login';
import selectEntity from '../creator/selectEntity';
import deselectEntity from '../creator/deselectEntity';
import IEntity from '../model/IEntity';
import saveConfiguration from '../creator/saveConfiguration';
import IRuleSet from '../model/api/IRuleSet';
import startConfiguration from '../creator/startConfiguration';
import generateContract from '../creator/generateContract';
import shareVisual from '../creator/shareVisual';
import getRuleSetList from '../creator/getRuleSetList';
import resetRuleSet from '../creator/resetRuleSet';
import showCreateForm from '../creator/showCreateForm';
import showLoadForm from '../creator/showLoadForm';
import IUser from '../model/IUser';
import showSettingsForm from '../creator/showSettingsForm';
import editUser from '../creator/editUser';
import IWorkflowStep from '../model/IWorkflowStep';
import setStep from '../creator/setStep';
import showContractForm from '../creator/showContractForm';
import showVisualsForm from '../creator/showVisualsForm';
import showTemplateForm from '../creator/showTemplateForm';
import setConfiguration from '../creator/setConfiguration';
import Unloader from "./Unloader";
import copy from 'copy-to-clipboard';
import { getVisualsFrontend } from '../util/env';

export interface IProps {
    user?: IUser;
    token?: string;
    anonymous?: boolean;
    isTimedOut?: boolean;
    isCreateFormVisible?: boolean;
    isLoadFormVisible?: boolean;
    isSettingsFormVisible?: boolean;
    isContractFormVisible?: boolean;
    isVisualsFormVisible?: boolean;
    isTemplateFormVisible?: boolean;
    showCreateForm?: (show: boolean) => void;
    showLoadForm?: (show: boolean) => void;
    showSettingsForm?: (show: boolean) => void;
    showContractForm?: (show: boolean) => void;
    showVisualsForm?: (show: boolean) => void;
    showTemplateForm?: (show: boolean) => void;
    ruleSet?: IRuleSet;
    ruleSetList?: IRuleSet;
    configuration?: IConfiguration;
    login?: (userName: string, password: string) => void;
    logout?: () => void;
    getRuleSetList?: (token: string) => void;
    selectEntity: (entity: IEntity, deselectRelated: boolean) => void;
    deselectEntity: (entity: IEntity) => void;
    setStep: (step: IWorkflowStep) => void;
    setConfiguration: (configuration: IConfiguration) => void;
    startConfiguration: (token: string, configId: string, priceListId: string) => void;
    saveConfiguration: (token: string, configuration: IConfiguration) => void;
    generateContract: (token: string, configuration: IConfiguration) => void;
    shareVisual: (token: string, configuration: IConfiguration) => string;
    code: string;
    editUser: (token: string, user: IUser) => void;
}

class Index extends React.Component<IProps, {}> {
    public render() {
        if (this.props.anonymous) {
            return (
                <div>
                    <Header/>
                    <Content>
                        <Login
                            onLogin={this.props.login}
                            anonymous={this.props.anonymous}
                            isTimedOut={this.props.isTimedOut}
                        />
                    </Content>
                    <Footer/>
                </div>
            );
        }

        return (
            <div>
                <Header>
                    {this.props.configuration && (
                        <Unloader/>
                    )}
                    {this.renderSteps()}
                </Header>
                <Content>
                    {this.props.configuration && (
                        <Summary
                            configuration={this.props.configuration}
                            onShowContractForm={this.props.showContractForm.bind(this)}
                            onShowVisualsForm={this.props.showVisualsForm.bind(this)}
                            onShowTemplateForm={this.props.showTemplateForm.bind(this)}
                        />
                    )}
                    {this.props.configuration && (
                        <Selector
                            configuration={this.props.configuration}
                            onSelect={this.props.selectEntity}
                            onDeselect={this.props.deselectEntity}
                            onChangeStep={this.props.setStep}
                        />
                    )}
                </Content>
                <Footer>
                    {this.renderControls()}
                </Footer>
                {this.props.isTimedOut ? <Modal
                    title="Anmeldung"
                    closable={false}
                    visible={true}
                    destroyOnClose={true}
                    footer={null}>
                    <Login
                        onLogin={this.props.login}
                        anonymous={this.props.anonymous}
                        isTimedOut={this.props.isTimedOut}
                    />
                </Modal> : null}
            </div>
        );
    }

    protected renderSteps() {
        if (!this.props.configuration) {
            return null;
        }

        return (
            <ul>
                {this.props.configuration.scheme.workflowSteps.map((step, index) => {
                    const isFulfilled = isStepFulfilled(this.props.configuration, step);
                    return (
                        <li key={step.id || (new Date()).getTime()}>
                            <a
                                href="#"
                                className={isFulfilled ? 'cHighlight' : null}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    if (isFulfilled) {
                                        this.props.setStep(step);
                                    }

                                    return false;
                                }}
                            >{`${index + 1}. ${step.name}`}</a>
                        </li>
                    );
                })}
            </ul>
        );
    }

    protected renderControls() {
        return (
            <div>
                {this.renderCreateConfigurationModal()}
                {this.renderLoadConfigurationModal()}
                {this.renderSettingsModal()}
                {this.renderVisualsModal()}
                {this.renderContractModal()}
                {this.renderTemplateModal()}
                <div className="cLeft">
                    <div className="cNavButton">
                        <a href="#" onClick={(e) => {
                            this.props.getRuleSetList(this.props.token);
                            this.props.showCreateForm(true);
                        }}>
                            <i className="fas fa-plus-circle"></i> Neue Konfiguration
                        </a>
                    </div>
                    <div className="cNavButton">
                        <a href="#" onClick={(e) => {
                            this.props.getRuleSetList(this.props.token);
                            this.props.showLoadForm(true);
                        }}>
                            <i className="fas fa-address-card"></i> Konfiguration laden
                        </a>
                    </div>
                </div>
                <div className="cRight">
                    <div className="cNavButton">
                        <a href="#" onClick={this.props.logout}>
                            <i className="fas fa-sign-out-alt"></i> Logout
                        </a>
                    </div>
                    <div className="cNavButton">
                        <a href="#" onClick={() => {
                            this.props.showSettingsForm(true);
                        }}>
                            <i className="fas fa-cog"></i> Einstellungen
                        </a>
                    </div>
                </div>
                <div className="cClear"></div>
            </div>
        );
    }

    protected renderCreateConfigurationModal() {
        return this.props.ruleSetList && this.props.isCreateFormVisible && (
            <Modal
                title="Preisliste wählen"
                closable={true}
                visible={true}
                destroyOnClose={true}
                footer={null}
                onCancel={() => {
                    this.props.showCreateForm(false);
                }}>
                <CreateConfiguration
                    ruleSetList={this.props.ruleSetList}
                    onSuccess={(configId, priceListId) => {
                        this.props.showCreateForm(false);
                        this.props.startConfiguration(this.props.token, configId, priceListId);
                    }}
                />
            </Modal>
        );
    }

    protected renderLoadConfigurationModal() {
        return this.props.ruleSetList && this.props.isLoadFormVisible && (
            <Modal
                title="Konfiguration laden"
                closable={true}
                visible={true}
                destroyOnClose={true}
                footer={null}
                onCancel={() => {
                    this.props.showLoadForm(false);
                }}
            >
                <LoadConfiguration
                    ruleSetList={this.props.ruleSetList}
                    onSuccess={(configId, priceListId) => {
                        this.props.showLoadForm(false);
                        this.props.startConfiguration(this.props.token, configId, priceListId);
                    }}
                />
            </Modal>
        );
    }

    protected renderSettingsModal() {
        return this.props.isSettingsFormVisible && (
            <Modal
                title="Einstellungen ändern"
                closable={true}
                visible={true}
                destroyOnClose={true}
                footer={null}
                onCancel={() => {
                    this.props.showSettingsForm(false);
                }}
            >
                <Settings
                    user={this.props.user}
                    onSuccess={(user: IUser) => {
                        this.props.showSettingsForm(false);
                        this.props.editUser(this.props.token, user);
                    }}
                />
            </Modal>
        );
    }

    protected renderTemplateModal() {
        return this.props.isTemplateFormVisible && (
            <Modal
                title="als Vorlage speichern"
                closable={true}
                visible={true}
                destroyOnClose={true}
                footer={null}
                onCancel={() => {
                    this.props.showTemplateForm(false);
                }}
            >
                <ConfigurationForm
                    configuration={this.props.configuration}
                    onSetConfiguration={this.props.setConfiguration.bind(this)}
                    onSubmit={(configuration: IConfiguration) => {
                        this.props.saveConfiguration(this.props.token, configuration);
                        this.props.showTemplateForm(false);
                    }}
                />
            </Modal>
        );
    }

    protected renderVisualsModal() {
        return this.props.isVisualsFormVisible && (
            <Modal
                title="Visualisierung teilen"
                closable={true}
                visible={true}
                destroyOnClose={true}
                footer={null}
                onCancel={() => {
                    this.props.showVisualsForm(false);
                }}
            >
                <Visuals
                    customer={this.props.configuration}
                    onSubmit={(configuration: IConfiguration) => {
                        this.props.shareVisual(this.props.token, configuration);
                        this.props.showVisualsForm(false);
                    }}
                    submitLabel="Teilen"
                />
            </Modal>
        );
    }

    protected renderContractModal() {
        return this.props.isContractFormVisible && (
            <Modal
                title="Vertragsunterlagen erstellen"
                closable={true}
                visible={true}
                destroyOnClose={true}
                footer={null}
                onCancel={() => {
                    this.props.showContractForm(false);
                }}
            >
                <ConfigurationForm
                    configuration={this.props.configuration}
                    onSetConfiguration={this.props.setConfiguration.bind(this)}
                    onSubmit={(configuration: IConfiguration) => {
                        this.props.generateContract(this.props.token, configuration);
                        this.props.showContractForm(false);
                    }}
                />
            </Modal>
        );
    }
}

export default connect(
    (state: any): Partial<IProps> => {
        return {
            user: get(state, 'auth', null),
            token: get(state, 'auth.token', true),
            anonymous: get(state, 'auth.anonymous', true),
            isTimedOut: get(state, 'auth.isTimedOut', false),
            isCreateFormVisible: get(state, 'showCreateForm', false),
            isLoadFormVisible: get(state, 'showLoadForm', false),
            isSettingsFormVisible: get(state, 'showSettingsForm', false),
            isContractFormVisible: get(state, 'showContractForm', false),
            isVisualsFormVisible: get(state, 'showVisualsForm', false),
            isTemplateFormVisible: get(state, 'showTemplateForm', false),
            ruleSet: get(state, 'ruleSet', null),
            ruleSetList: get(state, 'ruleSetList', null),
            configuration: get(state, 'configuration', null),
        } as Partial<IProps>
    },
    (dispatch) => ({
        login: (userName: string, password: string) => {
            dispatch(loginCreator(userName, password)).catch(() => {
                notification['error']({
                    message: 'Fehler',
                    description: 'Der Benutzer konnte nicht angemeldet werden.'
                });
            });
        },
        logout: () => {
            dispatch(logoutCreator());
        },
        getRuleSetList: (token: string) => {
            dispatch(resetRuleSet()).then(() => {
                dispatch(getRuleSetList(token, 'sales')).catch(() => {
                    dispatch(showCreateForm(false));
                    dispatch(showLoadForm(false));

                    notification['error']({
                        message: 'Fehler',
                        description: 'Das Regelwerk konnte nicht geladen werden.'
                    });
                });
            });
        },
        showCreateForm: (show: boolean) => {
            dispatch(showCreateForm(show));
        },
        showLoadForm: (show: boolean) => {
            dispatch(showLoadForm(show));
        },
        showSettingsForm: (show: boolean) => {
            dispatch(showSettingsForm(show));
        },
        showVisualsForm: (show: boolean) => {
            dispatch(showVisualsForm(show));
        },
        showContractForm: (show: boolean) => {
            dispatch(showContractForm(show));
        },
        showTemplateForm: (show: boolean) => {
            dispatch(showTemplateForm(show));
        },
        selectEntity: (entity: IEntity, deselectRelated: boolean) => {
            dispatch(selectEntity(entity, deselectRelated));
        },
        deselectEntity: (entity: IEntity) => {
            dispatch(deselectEntity(entity));
        },
        setStep: (step: IWorkflowStep) => {
            dispatch(setStep(step));
        },
        setConfiguration: (configuration: IConfiguration) => {
            dispatch(setConfiguration(configuration));
        },
        startConfiguration: (token: string, configId: string, priceListId: string) => {
            dispatch(startConfiguration(token, 'sales', configId, priceListId));
        },
        saveConfiguration: (token: string, configuration: IConfiguration) => {
            dispatch(saveConfiguration(token, configuration)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Die Konfiguration wurde gespeichert.'
                });
            }).catch(() => {
                notification['error']({
                    message: 'Fehler',
                    description: 'Die Konfiguration konnte nicht gespeichert werden.'
                });
            });
        },
        shareVisual: (token: string, configuration: IConfiguration) => {
            dispatch(shareVisual(token, configuration)).then((code) => {
                notification['success']({
                    duration: 0,
                    message: 'Visualisierung geteilt',
                    description:
                        <div>
                            Der Visualisierungslink (Code: {code}) wurde generiert.
                            <Button onClick={
                                () => {copy(getVisualsFrontend() + '/#/visuals/' + code)}
                            }>
                                In die Zwischenablage kopieren
                            </Button>
                        </div>
                });
            }).catch(() => {
                notification['error']({
                    message: 'Fehler',
                    description: 'Die Visualisierungen konnten nicht geteilt werden.'
                });
            });
        },
        generateContract: (token: string, configuration: IConfiguration) => {
            dispatch(generateContract(token, configuration)).catch(() => {
                notification['error']({
                    message: 'Fehler',
                    description: 'Die Vertragsunterlagen konnten nicht generiert werden.'
                });
            });
        },
        editUser: (token: string, user: IUser) => {
            dispatch(editUser(token, user)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Der Benutzer wurde gespeichert.'
                });
            }).catch(() => {
                notification['error']({
                    message: 'Fehler',
                    description: 'Der Benutzer konnte nicht gespeichert werden.'
                });
            });
        }
    } as Partial<IProps>)
)(Index) as any;