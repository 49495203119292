import * as React from 'react';
import { assign } from 'lodash';
import { Input, Button, Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import IUser from '../model/IUser';

export interface IProps extends FormComponentProps {
    user: IUser;
    onSuccess: (user: IUser) => void;
}

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Settings extends React.Component<IProps, {}> {
    public render() {
        const {getFieldDecorator, getFieldsError, getFieldError, setFieldsValue, getFieldValue} = this.props.form;
        const firstNameError = getFieldError('firstName');
        const lastNameError = getFieldError('lastName');
        const passwordError = getFieldError('password');
        const passwordRepeatError = getFieldError('passwordRepeat');
        const emailError = getFieldError('email');

        return (
            <Form onSubmit={this.onSubmit.bind(this)}>
                <Form.Item
                    validateStatus={firstNameError ? 'error' : 'success'}
                    help={firstNameError || ''}
                    label="Vorname"
                >
                    {getFieldDecorator('firstName', {
                        initialValue: this.props.user.firstName,
                        rules: [{required: true, message: 'Bitte Vorname eingeben.'}],
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    firstName: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={lastNameError ? 'error' : 'success'}
                    help={lastNameError || ''}
                    label="Nachname"
                >
                    {getFieldDecorator('lastName', {
                        initialValue: this.props.user.lastName,
                        rules: [{required: true, message: 'Bitte Nachname eingeben.'}],
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    lastName: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={emailError ? 'error' : 'success'}
                    help={emailError || ''}
                    label="E-Mail"
                >
                    {getFieldDecorator('email', {
                        initialValue: this.props.user.email,
                        rules: [{required: true, message: 'Bitte E-Mail eingeben.'}],
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    email: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={passwordError ? 'error' : 'success'}
                    help={passwordError || ''}
                    label="Neues Passwort"
                >
                    {getFieldDecorator('password', {
                        initialValue: null,
                    })(
                        <Input
                            type="password"
                            onChange={(e) => {
                                setFieldsValue({
                                    password: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={passwordRepeatError ? 'error' : 'success'}
                    help={passwordRepeatError || ''}
                    label="Neues Passwort wiederholen"
                >
                    {getFieldDecorator('passwordRepeat', {
                        initialValue: null,
                        rules: [
                            {
                                validator: (rule, passwordRepeat, cb) => {
                                    const password = getFieldValue('password');

                                    if (!password && !passwordRepeat) {
                                        cb();
                                    } else if (passwordRepeat !== password) {
                                        cb('Passwörter sind nicht identisch.')
                                    } else {
                                        cb();
                                    }
                                }
                            },
                        ],
                    })(
                        <Input
                            type="password"
                            onChange={(e) => {
                                setFieldsValue({
                                    passwordRepeat: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={hasErrors(getFieldsError())}
                    >
                        übernehmen
                    </Button>
                </Form.Item>
            </Form>
        );
    }

    protected onSubmit(e: React.FormEvent<any>): void {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSuccess({
                    id: this.props.user.id,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    password: values.password,
                    email: values.email,
                    roles: this.props.user.roles
                });
            }
        });
    }
}

export default Form.create()(Settings);