import * as React from 'react';
import { assign } from 'lodash';
import { Steps, Button } from 'antd';
import IConfiguration from '../model/IConfiguration';
import Customer from './form/Customer';
import Property from './form/Property';
import OwnPerformance from './form/OwnPerformance';
import IncidentalCosts from './form/IncidentalCosts';

export interface IProps {
    configuration: IConfiguration;
    onSetConfiguration: (configuration: IConfiguration) => void;
    onSubmit: (configuration: IConfiguration) => void;
}

export interface IState {
    currentStep: number;
}

class ConfigurationForm extends React.Component<IProps, IState> {
    constructor(props: IProps, context: {}) {
        super(props, context);

        this.state = {
            currentStep: 0
        }
    }

    public render() {
        return (
            <div>
                <Steps
                    current={this.state.currentStep}
                    size='small'
                >
                    <Steps.Step
                        key='Kundendaten'
                        title='Kundendaten'
                    />
                    <Steps.Step
                        key='Grundstück'
                        title='Grundstück'
                    />
                    <Steps.Step
                        key='Eigenleistungen'
                        title='Eigenleistungen'
                    />
                    <Steps.Step
                        key='Baunebenkosten'
                        title='Baunebenkosten'
                    />
                </Steps>
                <br/>
                {this.state.currentStep === 0 && (
                    <Customer
                        customer={this.props.configuration}
                        onSubmit={(customer) => {
                            this.setState({
                                currentStep: 1,
                            });

                            this.props.onSetConfiguration(
                                assign({}, this.props.configuration, customer)
                            );
                        }}
                        submitLabel="weiter"
                    />
                )}
                {this.state.currentStep === 1 && (
                    <Property
                        property={this.props.configuration}
                        onSubmit={(property) => {
                            this.setState({
                                currentStep: 2,
                            });

                            this.props.onSetConfiguration(
                                assign({}, this.props.configuration, property)
                            );
                        }}
                        submitLabel="weiter"
                    />
                )}
                {this.state.currentStep === 2 && (
                    <OwnPerformance
                        selectedEntities={this.props.configuration.entities}
                        ownPerformance={this.props.configuration.ownPerformance}
                        onSubmit={(ownPerformance) => {
                            this.setState({
                                currentStep: 3,
                            });

                            this.props.onSetConfiguration(
                                assign({}, this.props.configuration, {
                                    ownPerformance: ownPerformance
                                })
                            );
                        }}
                        submitLabel="weiter"
                    />
                )}
                {this.state.currentStep === 3 && (
                    <IncidentalCosts
                        incidentalCosts={this.props.configuration.incidentalCosts}
                        onSubmit={(incidentalCosts) => {
                            this.props.onSetConfiguration(
                                assign({}, this.props.configuration, {
                                    incidentalCosts: incidentalCosts
                                })
                            );

                            this.props.onSubmit(
                                assign({}, this.props.configuration, {
                                    incidentalCosts: incidentalCosts
                                })
                            );
                        }}
                        submitLabel="übernehmen"
                    />
                )}
                {this.state.currentStep !== 0 && (
                    <Button
                        onClick={() => {
                            this.setState({
                                currentStep: this.state.currentStep - 1
                            });
                        }}
                    >
                        zurück
                    </Button>
                )}
            </div>
        );
    }
}

export default ConfigurationForm;