import ISetAuth from './ISetAuth';
import ISetBusy from './ISetBusy';
import ISetConfiguration from './ISetConfiguration';
import ISetRuleSet from './ISetRuleSet';
import ISetRuleSetList from './ISetRuleSetList';
import IShowCreateForm from './IShowCreateForm';
import IShowLoadForm from './IShowLoadForm';
import IShowSettingsForm from './IShowSettingsForm';
import IShowContractForm from './IShowContractForm';
import IShowVisualsForm from './IShowVisualsForm';
import IShowTemplateForm from './IShowTemplateForm';

export type actions =
    ISetAuth
    | ISetBusy
    | ISetConfiguration
    | ISetRuleSet
    | ISetRuleSetList
    | IShowCreateForm
    | IShowLoadForm
    | IShowSettingsForm
    | IShowContractForm
    | IShowVisualsForm
    | IShowTemplateForm;

export enum types {
    set_auth = 'set_auth',
    set_busy = 'set_busy',
    set_configuration = 'set_configuration',
    set_ruleset = 'set_ruleset',
    set_rulesetlist = 'set_rulesetlist',
    show_create_form = 'show_create_form',
    show_load_form = 'show_load_form',
    show_settings_form = 'show_settings_form',
    show_contract_form = 'show_contract_form',
    show_template_form = 'show_template_form',
    show_visuals_form = 'show_visuals_form'
};