import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Index from './component/Index';

import 'normalize.css';
import './css/main.css';
import 'antd/dist/antd.less';

ReactDOM.render(
    <Index/>,
    document.getElementById('app')
);