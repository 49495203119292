import { omit, assign } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { types } from '../action/constants';
import IConfiguration from '../model/IConfiguration';
import timeout from './timeout';
import { getApiHost, getApiPort, getApiProtocol } from '../util/env';

export default function generateContract(token: string, configuration: IConfiguration): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: types.set_busy,
                busy: true
            });

            fetch(`${getApiProtocol()}://${getApiHost()}:${getApiPort()}/configuration/pdf`, {
                body: JSON.stringify(
                    assign({}, omit(
                        configuration,
                        ['id', 'isDefault', 'workflowStep', 'priceList']
                    ), {
                        entities: configuration.entities.map((entity) => {
                            return entity.id
                        }),
                        scheme: configuration.scheme.id,
                        priceList: configuration.priceList.id
                    })
                ),
                cache: 'no-cache',
                headers: {
                    'content-type': 'application/json',
                    'X-AUTH-TOKEN': token
                },
                method: 'POST',
                mode: 'cors',
                redirect: 'follow'
            }).then((response) => {
                if (!response.ok) {
                    throw response;
                }

                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                return response.blob();
            }).then((blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'contract.pdf';
                document.body.appendChild(a);
                a.click();

                setTimeout(() => {
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                    resolve();
                }, 100);
            }).catch((response) => {
                if (response.status === 401) {
                    dispatch(timeout());
                }

                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                reject();
            });
        });
    }
}