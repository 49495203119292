import { find, filter, findIndex, intersection, forEach } from 'lodash';
import IConfiguration from '../model/IConfiguration';
import IEntityType from '../model/IEntityType';
import IEntity from '../model/IEntity';
import IReference from '../model/IReference';
import IPrice from '../model/IPrice';
import IFile from '../model/api/IFile';
import IWorkflowStep from '../model/IWorkflowStep';
import IWorkflowSubStep from '../model/IWorkflowSubStep';

export function getPriceOfEntity(configuration: IConfiguration, entity: IEntity): IPrice {
    const selectedEntityIds: string[] = configuration.entities.map((entity) => {
        return entity.id
    });

    return find(configuration.priceList.prices, (price) => {
        return price.entity.id === entity.id && (price.relatedEntity === null || selectedEntityIds.indexOf(price.relatedEntity.id) !== -1);
    }) || {
        id: null,
        relatedEntity: null,
        entity: entity,
        price: 0
    };
}

export function hasEntityReferenceToEntity(references: IReference[], currentEntity: IEntity, selectedEntities: IEntity[]): boolean {
    let forbidden: number = 0;

    forEach(references, (reference) => {
        let inA = false;
        let inB: string[] = [];
        let notInB: string[] = [];

        // entityType : entityType
        if(reference.relatedEntityTypesA.length !== 0 && reference.relatedEntityTypesB.length !== 0) {
            forEach(reference.relatedEntityTypesA, (entitiesA) => {
                if(entitiesA === currentEntity.entityType.id) {
                    inA = true;
                }
            });

            if(!inA) {
                return;
            }

            forEach(reference.relatedEntityTypesB, (entitiesB) => {
                if(find(selectedEntities, {entityType: {id:entitiesB}})) {
                    inB.push(entitiesB);
                } else {
                    notInB.push(entitiesB);
                }
            });
        }

        //entityType : entity
        if(reference.relatedEntityTypesA.length !== 0 && reference.relatedEntitiesB.length !== 0) {
            forEach(reference.relatedEntityTypesA, (entitiesA) => {
                if(entitiesA === currentEntity.entityType.id) {
                    inA = true;
                }
            });

            if(!inA) {
                return;
            }

            forEach(reference.relatedEntitiesB, (entitiesB) => {
                if(find(selectedEntities, {id:entitiesB})) {
                    inB.push(entitiesB);
                } else {
                    notInB.push(entitiesB);
                }
            });
        }

        //entity : entityType
        if(reference.relatedEntitiesA.length !== 0 && reference.relatedEntityTypesB.length !== 0) {
            forEach(reference.relatedEntitiesA, (entitiesA) => {
                if(entitiesA === currentEntity.id) {
                    inA = true;
                }
            });

            if(!inA) {
                return;
            }

            forEach(reference.relatedEntityTypesB, (entitiesB) => {
                if(find(selectedEntities, {entityType: {id:entitiesB}})) {
                    inB.push(entitiesB);
                } else {
                    notInB.push(entitiesB);
                }
            });
        }

        //entity : entity
        if(reference.relatedEntitiesA.length !== 0 && reference.relatedEntitiesB.length !== 0) {
            forEach(reference.relatedEntitiesA, (entitiesA) => {
                if(entitiesA === currentEntity.id) {
                    inA = true;
                }
            });

            if(!inA) {
                return;
            }

            forEach(reference.relatedEntitiesB, (entitiesB) => {
                if(find(selectedEntities, {id:entitiesB})) {
                    inB.push(entitiesB);
                } else {
                    notInB.push(entitiesB);
                }
            });
        }

        if(inA && reference.constraint === 'block') {
            if(reference.operator === 'and' && notInB.length === 0) {
                forbidden++;
            } else if(reference.operator === 'or' && inB.length > 0) {
                forbidden++;
            } else {
                return;
            }
        } else if(inA && reference.constraint === 'need') {
            if(reference.operator === 'and' && notInB.length === 0) {
                return;
            } else if(reference.operator === 'or' && inB.length > 0) {
                return;
            } else {
                forbidden++;
            }
        } else if (!inA || inB.length === 0) {
            return;
        }
        forbidden++;
    });

    return (forbidden > 0 ? false : true)
};

export function getSelectableEntities(configuration: IConfiguration, entityType: IEntityType): IEntity[] {
    let selectableEntities: IEntity[] = [];

    entityType.entities.forEach((selectableEntity) => {
        const isSelectable = hasEntityReferenceToEntity(
            configuration.scheme.references,
            selectableEntity,
            configuration.entities
        );

        if (isSelectable) {
            selectableEntities.push(selectableEntity);
        }
    });

    return selectableEntities;
};

export function getUnfulfilledSteps(configuration: IConfiguration, workflowStep: IWorkflowStep): IWorkflowSubStep[] {
    return filter(workflowStep.subSteps, (subStep) => {
        if (!subStep.required || getSelectableEntities(configuration, subStep.relatedEntityType).length === 0) {
            return;
        }

        const selectedEntity = filter(configuration.entities, (entity) => {
            return entity.entityType.id === subStep.relatedEntityType.id;
        });

        return selectedEntity.length === 0;
    });
}

export function getUnfulfilledFullSteps(configuration: IConfiguration): IWorkflowStep[] {
    let unfillfilledSteps: IWorkflowStep[] = [];
    for (let i = 0; i < configuration.scheme.workflowSteps.length; i++) {
        if (!isStepFulfilled(configuration, configuration.scheme.workflowSteps[i])) {
            unfillfilledSteps.push(configuration.scheme.workflowSteps[i]);
        }
    }

    return unfillfilledSteps;
}

export function isStepFulfilled(configuration: IConfiguration, workflowStep: IWorkflowStep): boolean {
    return getUnfulfilledSteps(configuration, workflowStep).length === 0;
}

export function isEachStepFulfilled(configuration: IConfiguration): boolean {
    for (let i = 0; i < configuration.scheme.workflowSteps.length; i++) {
        if (!isStepFulfilled(configuration, configuration.scheme.workflowSteps[i])) {
            return false;
        }
    }

    return true;
}

export function getNextStep(configuration: IConfiguration): IWorkflowStep {
    const index = findIndex(configuration.scheme.workflowSteps, {id: configuration.workflowStep.id});

    for (let i = index + 1; i < configuration.scheme.workflowSteps.length; i++) {
        for (let j = 0; j < configuration.scheme.workflowSteps[i].subSteps.length; j++) {
            if (getSelectableEntities(configuration, configuration.scheme.workflowSteps[i].subSteps[j].relatedEntityType).length > 0) {
                return configuration.scheme.workflowSteps[i];
            }
        }
    }

    throw new Error('There is no next step.');
}

export function getPreviousStep(configuration: IConfiguration): IWorkflowStep {
    const index = findIndex(configuration.scheme.workflowSteps, {id: configuration.workflowStep.id});

    for (let i = index - 1; i >= 0; i--) {
        for (let j = 0; j < configuration.scheme.workflowSteps[i].subSteps.length; j++) {
            if (getSelectableEntities(configuration, configuration.scheme.workflowSteps[i].subSteps[j].relatedEntityType).length > 0) {
                return configuration.scheme.workflowSteps[i];
            }
        }
    }

    throw new Error('There is no previous step.');
}

export function getOverallPrice(configuration: IConfiguration): number {
    const prices = configuration.entities.map((entity) => {
        return getPriceOfEntity(configuration, entity).price;
    });

    return prices.length > 0 ? prices.reduce((a, b) => a + b) : 0;
}

export function getVisuals(configuration: IConfiguration): IFile[] {
    let currentTags: string[] = [];

    configuration.entities.forEach((entity) => {
        currentTags = currentTags.concat(entity.tags);
    });

    let visuals: IFile[] = [];

    forEach(configuration.scheme.visuals, (visual) => {
        if (intersection(visual.t, currentTags).length !== visual.t.length) {
            return;
        }

        visuals.push(visual);
    });

    return visuals;
}
