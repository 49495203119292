import { assign } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { types } from '../action/constants';
import IConfiguration from '../model/IConfiguration';
import timeout from './timeout';
import { getVisuals } from '../util/configuration';
import { getApiHost, getApiPort, getApiProtocol } from '../util/env';

export default function shareVisual(token: string, configuration: IConfiguration): ThunkAction<Promise<string>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: types.set_busy,
                busy: true
            });

            fetch(`${getApiProtocol()}://${getApiHost()}:${getApiPort()}/visual/create`, {
                body: JSON.stringify(
                    assign({}, {
                        firstName1: configuration.firstName1,
                        firstName2: configuration.firstName2,
                        lastName1: configuration.lastName1,
                        lastName2: configuration.lastName2,
                        visuals: getVisuals(configuration),
                    })
                ),
                cache: 'no-cache',
                headers: {
                    'content-type': 'application/json',
                    'X-AUTH-TOKEN': token
                },
                method: 'POST',
                mode: 'cors',
                redirect: 'follow'
            }).then((response) => {
                if (!response.ok) {
                    throw response;
                }

                return response.json();
            }).then((result) => {
                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                resolve(result.code);
            }).catch((response: Response) => {
                if (response.status === 401) {
                    dispatch(timeout());
                }

                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                reject();
            });
        });
    }
}