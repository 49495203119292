import * as React from 'react';
import { find, get, filter } from 'lodash';
import IConfiguration from '../model/IConfiguration';
import * as configuration from '../util/configuration';
import IEntity from '../model/IEntity';
import IWorkflowSubStep from '../model/IWorkflowSubStep';
import { Icon, Popover, Button, Row, Col, Card, Checkbox, List } from 'antd';
import IPrice from '../model/IPrice';
import Slider from 'react-slick';
import { getApiHost, getApiPort, getApiProtocol } from '../util/env';
import { format } from '../util/price';

import '../../node_modules/slick-carousel/slick/slick.less';
import '../../node_modules/slick-carousel/slick/slick-theme.less';
import IWorkflowStep from '../model/IWorkflowStep';
import IEntityField from '../model/IEntityField';

export interface IProps {
    configuration: IConfiguration;
    onSelect: (entity: IEntity, deselectRelated: boolean) => void;
    onDeselect: (entity: IEntity) => void;
    onChangeStep: (step: IWorkflowStep) => void;
}

class Selector extends React.Component<IProps, {}> {
    public render() {
        let nextStep: IWorkflowStep;
        let previousStep: IWorkflowStep;

        try {
            nextStep = configuration.getNextStep(this.props.configuration);
        } catch (e) {
            nextStep = null;
        }

        try {
            previousStep = configuration.getPreviousStep(this.props.configuration);
        } catch (e) {
            previousStep = null;
        }

        const isCurrentStepFulfilled = configuration.isStepFulfilled(
            this.props.configuration,
            this.props.configuration.workflowStep
        );

        const unfullFilledSteps = configuration.getUnfulfilledSteps(
            this.props.configuration,
            this.props.configuration.workflowStep
        ).map((step) => {
            return <div key={step.name}>{step.name}</div>;
        });

        return (
            <div>
                <h1 style={{textAlign: 'center'}}>
                    {previousStep && <Button
                        type="primary"
                        onClick={() => this.props.onChangeStep(previousStep)}
                        style={{float: 'left'}}
                    >
                        zurück
                    </Button>}
                    {this.props.configuration.workflowStep.description}
                    {nextStep && unfullFilledSteps.length > 0 && <Popover
                        placement="bottomRight"
                        content={unfullFilledSteps}
                        title="Fehlende Schritte"
                    ><Button
                        type="primary"
                        onClick={() => this.props.onChangeStep(nextStep)}
                        disabled={!isCurrentStepFulfilled}
                        style={{float: 'right'}}
                    >
                        weiter
                    </Button></Popover>}
                    {nextStep && unfullFilledSteps.length === 0 && <Button
                        type="primary"
                        onClick={() => this.props.onChangeStep(nextStep)}
                        disabled={!isCurrentStepFulfilled}
                        style={{float: 'right'}}
                    >
                        weiter
                    </Button>}
                </h1>
                {this.props.configuration.workflowStep.subSteps.map((subStep) => {
                    switch (subStep.type) {
                        case 'list':
                            return this.renderList(subStep);
                        case 'tiles':
                            return this.renderTiles(subStep);
                    }
                })}
                <h1 style={{textAlign: 'center'}}>
                    {previousStep && <Button
                        type="primary"
                        onClick={() => this.props.onChangeStep(previousStep)}
                        style={{float: 'left'}}
                    >
                        zurück
                    </Button>}
                    {nextStep && unfullFilledSteps.length > 0 && <Popover
                        placement="topLeft"
                        content={unfullFilledSteps}
                        title="Fehlende Schritte"
                    ><Button
                        type="primary"
                        onClick={() => this.props.onChangeStep(nextStep)}
                        disabled={!isCurrentStepFulfilled}
                        style={{float: 'right'}}
                    >
                        weiter
                    </Button></Popover>}
                    {nextStep && unfullFilledSteps.length === 0 && <Button
                        type="primary"
                        onClick={() => this.props.onChangeStep(nextStep)}
                        disabled={!isCurrentStepFulfilled}
                        style={{float: 'right'}}
                    >
                        weiter
                    </Button>}
                </h1>
            </div>
        );
    }

    protected renderTiles(subStep: IWorkflowSubStep) {
        const entityType = find(this.props.configuration.scheme.entityTypes, {id: subStep.relatedEntityType.id});
        const selectableEntities = configuration.getSelectableEntities(
            this.props.configuration,
            entityType
        );

        if (selectableEntities.length === 0) {
            return null;
        }

        const elements: React.ReactElement<any>[] = filter(entityType.entities, (entity) => {
            const isDisabled = !find(selectableEntities, {id: entity.id});

            return subStep.displayInvalidOptions || subStep.displayInvalidOptions === false && !isDisabled;
        }).map((entity) => {
            const isSelected = !!find(this.props.configuration.entities, {id: entity.id});
            const isDisabled = !find(selectableEntities, {id: entity.id});

            return this.renderTile(
                entity,
                configuration.getPriceOfEntity(this.props.configuration, entity),
                isSelected,
                isDisabled,
                subStep.multiple
            );
        });

        let cols: React.ReactElement<any>[] = [];

        const rows = elements.reduce((acc, p, i) => {
            cols.push(p);

            if (i % 3 === 2) {
                acc.push(<Row gutter={32} key={`row-${i}`}>{cols}</Row>);
                cols = [];
            }
            return acc;
        }, []);

        rows.push(<Row gutter={32} key="row-last">{cols}</Row>);

        return (
            <div key={subStep.id}>
                <h2>{subStep.description}</h2>
                {rows}
            </div>
        );
    }

    protected renderList(subStep: IWorkflowSubStep) {
        const entityType = find(this.props.configuration.scheme.entityTypes, {id: subStep.relatedEntityType.id});
        const selectableEntities = configuration.getSelectableEntities(
            this.props.configuration,
            entityType
        );

        if (selectableEntities.length === 0) {
            return null;
        }

        return (
            <div key={subStep.id}>
                <h2>{subStep.description}</h2>
                {filter(entityType.entities, (entity) => {
                    const isDisabled = !find(selectableEntities, {id: entity.id});

                    return subStep.displayInvalidOptions || subStep.displayInvalidOptions === false && !isDisabled;
                }).map((entity) => {
                    const isSelected = !!find(this.props.configuration.entities, {id: entity.id});
                    const isDisabled = !find(selectableEntities, {id: entity.id});

                    return this.renderListItem(
                        entity,
                        configuration.getPriceOfEntity(this.props.configuration, entity),
                        isSelected,
                        isDisabled,
                        subStep.multiple
                    );
                })}
            </div>
        );
    }

    protected renderListItem(entity: IEntity, price: IPrice, isSelected: boolean, isDisabled: boolean, multiple: boolean) {
        const description = filter(entity.entityType.entityFields, (entityField: IEntityField) => {
            return entityField.type !== 'pdfUpload' && get(entity.data, entityField.name, null) !== null;
        }).map((entityField) => {
            let fieldValue = get(entity.data, entityField.name, null);

            if(entityField.type === 'imageUpload') {
                fieldValue = <div>
                    <a href={`${getApiProtocol()}://${getApiHost()}:${getApiPort()}/uploads/${fieldValue}`} target="_blank">
                        <img style={{width: '100px'}} src={`${getApiProtocol()}://${getApiHost()}:${getApiPort()}/uploads/${fieldValue}`}/>
                    </a>
                </div>;
            }

            return fieldValue ? (
                <span
                    key={`description-${entity.id}-${entityField.id}`}><strong>{entityField.name}</strong>: {fieldValue}&nbsp;</span>) : null;
        });

        return (
            <div onClick={(e) => {
                if (isSelected) {
                    this.props.onDeselect(entity);
                    e.stopPropagation();
                } else {
                    this.props.onSelect(entity, !multiple);
                    e.stopPropagation();
                }
            }}>
                <List
                    bordered
                    dataSource={[entity]}
                    renderItem={(entity: IEntity) => (
                        <List.Item>
                            <List.Item.Meta
                                title={<span>
                                <span style={{float: 'right'}} onClick={(e) => {
                                    e.stopPropagation();
                                }}>
                                    <span style={{marginRight: '15px'}}>
                                        <Popover content={description} title="Beschreibung">
                                            <Icon type="question-circle-o"/> Details
                                        </Popover>
                                    </span>
                                    <Checkbox
                                        style={{marginRight: '15px'}}
                                        checked={isSelected}
                                        disabled={isDisabled}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.props.onSelect(entity, !multiple);
                                                e.stopPropagation();
                                            } else {
                                                this.props.onDeselect(entity);
                                                e.stopPropagation();
                                            }
                                        }}
                                    />
                                </span>
                                <span>
                                    <strong>{entity.name}</strong>&nbsp;({format(price.price)})
                                </span>
                            </span>}
                            />
                        </List.Item>
                    )}
                />
            </div>
        );
    }

    protected renderTile(entity: IEntity, price: IPrice, isSelected: boolean, isDisabled: boolean, multiple: boolean) {
        const description = filter(entity.entityType.entityFields, (entityField) => {
            return entityField.type !== 'pdfUpload' && entityField.type !== 'imageUpload' && get(entity.data, entityField.name, null) !== null;
        }).map((entityField) => {
            const fieldValue = get(entity.data, entityField.name, null);
            return fieldValue ? (
                <div
                    key={`description-${entity.id}-${entityField.id}`}><strong>{entityField.name}</strong>: {fieldValue}
                </div>) : null;
        });

        const images = filter(entity.entityType.entityFields, (entityField) => {
            return entityField.type === 'imageUpload' && get(entity.data, entityField.name, null) !== null;
        }).map((entityField) => {
            return get(entity.data, entityField.name, null);
        });

        return (
            <Col xs={24} sm={8} key={`col-${entity.id}`} style={{padding: '16px'}}
                 onClick={(e) => {
                     e.stopPropagation();
                     if (isSelected) {
                         this.props.onDeselect(entity);
                     } else {
                         this.props.onSelect(entity, !multiple);
                     }
                 }}>
                <Card
                    hoverable={!isDisabled}
                    className={isDisabled ? 'alpha' : null}
                    bordered={true}
                    title={<span>
                    <span style={{float: 'right'}}>
                        <Checkbox
                            checked={isSelected}
                            disabled={isDisabled}
                        />
                    </span>
                    <span>
                        <strong>{(entity.name.length > 22 ? entity.name.substring(0, 22) + '...' : entity.name)}</strong>&nbsp;({format(price.price)})
                    </span>
                </span>}
                    cover={images.length > 0 && <div>
                        <Slider dots={true} arrows={false}>
                            {images.map((image) => {
                                return (
                                    <div key={`image-${entity.id}-${image}`} className="selector-tile-image">
                                        <img
                                            src={`${getApiProtocol()}://${getApiHost()}:${getApiPort()}/uploads/${image}`}/>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>}
                >
                    <div>
                        <Popover content={description} title="Beschreibung">
                            <Icon type="question-circle-o"/> Details
                        </Popover>
                    </div>
                </Card>
            </Col>
        );
    }
}

export default Selector;
