import { omit, get, assign } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import ISetAuth from '../action/ISetAuth';
import { types } from '../action/constants';
import IUser from '../model/IUser';
import timeout from './timeout';
import IAuth from '../model/IAuth';
import { getApiHost, getApiPort, getApiProtocol } from '../util/env';

export default function editUser(token: string, user: IUser): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: types.set_busy,
                busy: true
            });

            fetch(`${getApiProtocol()}://${getApiHost()}:${getApiPort()}/user/edit`, {
                body: JSON.stringify(omit(user, ['userName'])),
                cache: 'no-cache',
                headers: {
                    'content-type': 'application/json',
                    'X-AUTH-TOKEN': token
                },
                method: 'POST',
                mode: 'cors',
                redirect: 'follow'
            }).then((response) => {
                if (!response.ok) {
                    throw response;
                }

                return response.json();
            }).then((result) => {
                const auth: IAuth = get(getState(), 'auth');
                const setAuth: ISetAuth = {
                    type: types.set_auth,
                    auth: assign({}, auth, {
                        firstName: result.firstName,
                        lastName: result.lastName,
                        email: result.email,
                        roles: result.roles,
                    })
                };

                dispatch(setAuth);

                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                resolve();
            }).catch((response: Response) => {
                if (response.status === 401) {
                    dispatch(timeout());
                }

                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                reject();
            });
        });
    }
}