import { get, assign, filter, findIndex } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { types } from '../action/constants';
import IConfiguration from '../model/IConfiguration';
import IEntity from '../model/IEntity';

export default function selectEntity(entity: IEntity, deselectRelated: boolean): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const configuration: IConfiguration = get(getState(), 'configuration');

            let buffer: IConfiguration = assign({}, configuration);

            const currentStepIndex = findIndex(configuration.scheme.workflowSteps, {
                id: configuration.workflowStep.id
            });

            buffer.entities = filter(buffer.entities, (item) => {
                for (let i = currentStepIndex + 1; i < configuration.scheme.workflowSteps.length; i++) {
                    for (let j = 0; j < configuration.scheme.workflowSteps[i].subSteps.length; j++) {
                        if (item.entityType.id === configuration.scheme.workflowSteps[i].subSteps[j].relatedEntityType.id) {
                            return false;
                        }
                    }
                }

                return true;
            });

            if (deselectRelated) {
                buffer.entities = filter(buffer.entities, (item) => {
                    return item.entityType.id !== entity.entityType.id
                });
            }

            buffer.entities.push(entity);

            dispatch({
                type: types.set_configuration,
                configuration: buffer
            });

            resolve();
        });
    }
}