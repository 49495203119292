import { ThunkAction } from 'redux-thunk';
import { types } from '../action/constants';
import IShowCreateForm from '../action/IShowCreateForm';

export default function showCreateForm(show: boolean): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const showCreateForm: IShowCreateForm = {
                type: types.show_create_form,
                show
            };

            dispatch(showCreateForm);

            resolve();
        });
    }
}