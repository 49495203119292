import { get, assign } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { types } from '../action/constants';
import IConfiguration from '../model/IConfiguration';
import IWorkflowStep from '../model/IWorkflowStep';

export default function setStep(step: IWorkflowStep): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const configuration: IConfiguration = get(getState(), 'configuration');

            let buffer: IConfiguration = assign({}, configuration, {
                workflowStep: step
            });

            dispatch({
                type: types.set_configuration,
                configuration: buffer,
            });

            resolve();
        });
    }
}