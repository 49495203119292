import { ThunkAction } from 'redux-thunk';
import { types } from '../action/constants';
import IShowLoadForm from '../action/IShowLoadForm';

export default function showLoadForm(show: boolean): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const showLoadForm: IShowLoadForm = {
                type: types.show_load_form,
                show
            };

            dispatch(showLoadForm);

            resolve();
        });
    }
}