import { ThunkAction } from 'redux-thunk';
import { types } from '../action/constants';
import IShowTemplateForm from '../action/IShowTemplateForm';

export default function showTemplateForm(show: boolean): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const showTemplateForm: IShowTemplateForm = {
                type: types.show_template_form,
                show
            };

            dispatch(showTemplateForm);

            resolve();
        });
    }
}