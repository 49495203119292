import { assign, omit, get } from 'lodash';
import IConfiguration from '../model/IConfiguration';
import IApiIConfiguration from '../model/api/IConfiguration';
import IApiEntity from '../model/api/IEntity';
import IApiEntityType from '../model/api/IEntityType';
import IApiEntityField from '../model/api/IEntityField';
import IApiScheme from '../model/api/IScheme';
import IEntity from '../model/IEntity';
import IEntityType from '../model/IEntityType';
import IEntityField from '../model/IEntityField';
import IScheme from '../model/IScheme';

const getEnhancedEntityByApiEntity = (apiEntity: IApiEntity, apiEntityType: IApiEntityType): IEntity => {
    return {
        id: apiEntity.id,
        data: apiEntity.d,
        tags: apiEntity.t,
        name: apiEntity.n,
        entityType: {
            id: apiEntityType.id,
            name: apiEntityType.n,
            entityFields: apiEntityType.eF.map((entityField) => {
                return getEnhancedEntityFieldByApiEntity(entityField, apiEntityType);
            })
        }
    };
};

const getEnhancedEntityFieldByApiEntity = (apiEntityField: IApiEntityField, apiEntityType: IApiEntityType): IEntityField => {
    return {
        id: apiEntityField.id,
        name: apiEntityField.n,
        type: apiEntityField.t,
    };
};

const getEnhancedEntityTypeByApiEntityType = (apiEntityType: IApiEntityType): IEntityType => {
    return {
        id: apiEntityType.id,
        name: apiEntityType.n,
        entityFields: apiEntityType.eF.map((entityField) => {
            return getEnhancedEntityFieldByApiEntity(entityField, apiEntityType);
        }),
        entities: apiEntityType.e.map((entity) => {
            return getEnhancedEntityByApiEntity(entity, apiEntityType);
        })
    };
};

const getEnhancedSchemeByApiScheme = (apiScheme: IApiScheme,
                                      enhancedEntityTypeMap: { [index: string]: IEntityType },
                                      enhancedEntityMap: { [index: string]: IEntity }): IScheme => {
    return {
        id: apiScheme.id,
        name: apiScheme.name,
        activatedAt: apiScheme.activatedAt,
        deactivatedAt: apiScheme.deactivatedAt,
        app: apiScheme.app,
        headerFile: apiScheme.headerFile,
        footerFile: apiScheme.footerFile,
        entityTypes: apiScheme.entityTypes.map((entityType) => {
            return get(enhancedEntityTypeMap, entityType.id, null);
        }),
        visuals: apiScheme.visuals,
        references: apiScheme.references.map((reference) => {
            return {
                id: reference.id,
                relatedEntitiesA: reference.rEA,
                relatedEntityTypesA: reference.rETA,
                relatedEntitiesB: reference.rEB,
                relatedEntityTypesB: reference.rETB,
                constraint: reference.constraint,
                operator: reference.operator,
            };
        }),
        priceLists: apiScheme.priceLists.map((priceList) => {
            return {
                id: priceList.id,
                name: priceList.name,
                relatedEntityType: get(enhancedEntityTypeMap, priceList.rET, null),
                prices: priceList.prices.map((price) => {
                    return {
                        id: price.id,
                        relatedEntity: get(enhancedEntityMap, price.rE, null),
                        entity: get(enhancedEntityMap, price.e, null),
                        price: price.p,
                    }
                }),
                roles: priceList.roles
            };
        }),
        workflowSteps: apiScheme.workflowSteps.map((workflowStep) => {
            return {
                id: workflowStep.id,
                name: workflowStep.name,
                description: workflowStep.desc,
                subSteps: workflowStep.sSteps.map((subStep) => {
                    return {
                        id: subStep.id,
                        name: subStep.n,
                        description: subStep.desc,
                        relatedEntityType: get(enhancedEntityTypeMap, subStep.rET, null),
                        type: subStep.t,
                        displayInvalidOptions: subStep.dIO,
                        multiple: subStep.multi,
                        required: subStep.req
                    }
                })
            };
        }),
        pdfs: apiScheme.pdfs
    };
};

export function getEnhancedConfigurationByApiConfiguration(apiConfiguration: IApiIConfiguration): IConfiguration {
    const enhancedEntityMap: { [index: string]: IEntity } = {};
    const enhancedEntityTypeMap: { [index: string]: IEntityType } = {};

    apiConfiguration.scheme.entityTypes.forEach((entityType) => {
        const enhancedEntityType = getEnhancedEntityTypeByApiEntityType(entityType);

        entityType.e.map((entity) => {
            enhancedEntityMap[entity.id] = getEnhancedEntityByApiEntity(entity, entityType)
        });

        enhancedEntityTypeMap[enhancedEntityType.id] = enhancedEntityType;
    });

    return {
        id: apiConfiguration.id,
        name: apiConfiguration.name,
        salutation1: apiConfiguration.salutation1,
        firstName1: apiConfiguration.firstName1,
        lastName1: apiConfiguration.lastName1,
        birthday1: apiConfiguration.birthday1,
        city1: apiConfiguration.city1,
        zip1: apiConfiguration.zip1,
        street1: apiConfiguration.street1,
        streetNumber1: apiConfiguration.streetNumber1,
        salutation2: apiConfiguration.salutation2,
        firstName2: apiConfiguration.firstName2,
        lastName2: apiConfiguration.lastName2,
        birthday2: apiConfiguration.birthday2,
        city2: apiConfiguration.city2,
        zip2: apiConfiguration.zip2,
        street2: apiConfiguration.street2,
        streetNumber2: apiConfiguration.streetNumber2,
        propertyCity: apiConfiguration.propertyCity,
        propertyPrice: apiConfiguration.propertyPrice,
        notary: apiConfiguration.notary,
        conveyanceDuty: apiConfiguration.conveyanceDuty,
        brokersCommission: apiConfiguration.brokersCommission,
        conditionalContractProperty: apiConfiguration.conditionalContractProperty,
        conditionalContractCredit: apiConfiguration.conditionalContractCredit,
        conditionalContractBEG: apiConfiguration.conditionalContractBEG,
        entities: apiConfiguration.entities.map((entityId) => {
            return get(enhancedEntityMap, entityId, null)
        }),
        scheme: getEnhancedSchemeByApiScheme(
            apiConfiguration.scheme,
            enhancedEntityTypeMap,
            enhancedEntityMap
        ),
        workflowStep: null,
        priceList: null,
        ownPerformance: apiConfiguration.ownPerformance,
        incidentalCosts: apiConfiguration.incidentalCosts || {
            engineeringWork: null,
            engineeringWorkOwnPerformance: null,
            siteFacilities: null,
            outdoorMaterials: null,
            outdoorWork: null,
            outdoorWorkOwnPerformance: null,
            mediaInterface: null,
            changes: null
        },
    };
}
