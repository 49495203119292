import * as React from 'react';
import { Form, Button, InputNumber } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import IIncidentalCosts from '../../model/IIncidentalCosts';

export interface IProps extends FormComponentProps {
    incidentalCosts: IIncidentalCosts;
    onSubmit: (data: IIncidentalCosts) => void;
    submitLabel: string;
}

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class IncidentalCosts extends React.Component<IProps, {}> {
    public render() {
        const {getFieldDecorator, getFieldsError, getFieldError, setFieldsValue} = this.props.form;

        const engineeringWorkError = getFieldError('engineeringWork');
        const engineeringWorkOwnPerformanceError = getFieldError('engineeringWorkOwnPerformance');
        const siteFacilitiesError = getFieldError('siteFacilities');
        const outdoorMaterialsError = getFieldError('outdoorMaterials');
        const outdoorWorkError = getFieldError('outdoorWork');
        const outdoorWorkOwnPerformanceError = getFieldError('outdoorWorkOwnPerformance');
        const mediaInterfaceError = getFieldError('mediaInterface');
        const changesError = getFieldError('changes');

        return (
            <Form onSubmit={this.onSubmit.bind(this)}>
                <Form.Item
                    validateStatus={engineeringWorkError ? 'error' : 'success'}
                    help={engineeringWorkError || ''}
                    label="Erd- und Tiefbauarbeiten"
                >
                    {getFieldDecorator('engineeringWork', {
                        initialValue: this.props.incidentalCosts.engineeringWork ? this.props.incidentalCosts.engineeringWork : 10000,
                        rules: [{required: true, message: 'Bitte Kosten für Erd- und Tiefbauarbeiten eingeben.'}],
                    })(
                        <InputNumber
                            onChange={(engineeringWork) => {
                                setFieldsValue({
                                    engineeringWork
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={engineeringWorkOwnPerformanceError ? 'error' : 'success'}
                    help={engineeringWorkOwnPerformanceError || ''}
                    label="Erd- und Tiefbauarbeiten Eigenleistung in %"
                >
                    {getFieldDecorator('engineeringWorkOwnPerformance', {
                        initialValue: this.props.incidentalCosts.engineeringWorkOwnPerformance ? this.props.incidentalCosts.engineeringWorkOwnPerformance : 0,
                    })(
                        <InputNumber
                            min={0}
                            max={100}
                            onChange={(engineeringWorkOwnPerformance) => {
                                setFieldsValue({
                                    engineeringWorkOwnPerformance
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={siteFacilitiesError ? 'error' : 'success'}
                    help={siteFacilitiesError || ''}
                    label="Baustelleneinrichtung"
                >
                    {getFieldDecorator('siteFacilities', {
                        initialValue: this.props.incidentalCosts.siteFacilities ? this.props.incidentalCosts.siteFacilities : 2000,
                        rules: [{required: true, message: 'Bitte Kosten für Baustelleneinrichtung eingeben.'}],
                    })(
                        <InputNumber
                            onChange={(siteFacilities) => {
                                setFieldsValue({
                                    siteFacilities
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={outdoorMaterialsError ? 'error' : 'success'}
                    help={outdoorMaterialsError || ''}
                    label="Außenanlage Material"
                >
                    {getFieldDecorator('outdoorMaterials', {
                        initialValue: this.props.incidentalCosts.outdoorMaterials ? this.props.incidentalCosts.outdoorMaterials : 0,
                        rules: [{required: true, message: 'Bitte Materialkosten für Außenanlage eingeben.'}],
                    })(
                        <InputNumber
                            onChange={(outdoorMaterials) => {
                                setFieldsValue({
                                    outdoorMaterials
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={outdoorWorkError ? 'error' : 'success'}
                    help={outdoorWorkError || ''}
                    label="Außenanlage Leistung"
                >
                    {getFieldDecorator('outdoorWork', {
                        initialValue: this.props.incidentalCosts.outdoorWork ? this.props.incidentalCosts.outdoorWork : 0,
                        rules: [{required: true, message: 'Bitte Kosten für Außenanlage eingeben.'}],
                    })(
                        <InputNumber
                            onChange={(outdoorWork) => {
                                setFieldsValue({
                                    outdoorWork
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={outdoorWorkOwnPerformanceError ? 'error' : 'success'}
                    help={outdoorWorkOwnPerformanceError || ''}
                    label="Außenanlage Eigenleistung in %"
                >
                    {getFieldDecorator('outdoorWorkOwnPerformance', {
                        initialValue: this.props.incidentalCosts.outdoorWorkOwnPerformance ? this.props.incidentalCosts.outdoorWorkOwnPerformance : 0,
                    })(
                        <InputNumber
                            min={0}
                            max={100}
                            onChange={(outdoorWorkOwnPerformance) => {
                                setFieldsValue({
                                    outdoorWorkOwnPerformance
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={mediaInterfaceError ? 'error' : 'success'}
                    help={mediaInterfaceError || ''}
                    label="Medienanbindung/Hausanschluss"
                >
                    {getFieldDecorator('mediaInterface', {
                        initialValue: this.props.incidentalCosts.mediaInterface ? this.props.incidentalCosts.mediaInterface : 9000,
                        rules: [{required: true, message: 'Bitte Kosten für Medienanbindung/Hausanschluss eingeben.'}],
                    })(
                        <InputNumber
                            onChange={(mediaInterface) => {
                                setFieldsValue({
                                    mediaInterface
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    validateStatus={changesError ? 'error' : 'success'}
                    help={changesError || ''}
                    label="Bemusterungspauschale"
                >
                    {getFieldDecorator('changes', {
                        initialValue: this.props.incidentalCosts.changes ? this.props.incidentalCosts.changes : 0,
                        rules: [{required: true, message: 'Bitte Kosten für Änderungen eingeben.'}],
                    })(
                        <InputNumber
                            onChange={(changes) => {
                                setFieldsValue({
                                    changes
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={hasErrors(getFieldsError())}
                    >
                        {this.props.submitLabel}
                    </Button>
                </Form.Item>
            </Form>
        );
    }

    protected onSubmit(e: React.FormEvent<any>): void {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit({
                    engineeringWork: values.engineeringWork,
                    engineeringWorkOwnPerformance: values.engineeringWorkOwnPerformance,
                    siteFacilities: values.siteFacilities,
                    outdoorMaterials: values.outdoorMaterials,
                    outdoorWork: values.outdoorWork,
                    outdoorWorkOwnPerformance: values.outdoorWorkOwnPerformance,
                    mediaInterface: values.mediaInterface,
                    changes: values.changes,
                });
            }
        });
    }
}

export default Form.create()(IncidentalCosts);
