import { ThunkAction } from 'redux-thunk';
import { types } from '../action/constants';
import ISetConfiguration from '../action/ISetConfiguration';
import { find, assign, get } from 'lodash';
import {getEnhancedConfigurationByApiConfiguration} from "../util/api";
import { getApiHost, getApiPort, getApiProtocol } from '../util/env';
import timeout from "./timeout";
import ISetRuleSet from "../action/ISetRuleSet";

export default function startConfiguration(
    token: string,
    app: string,
    configId: string,
    priceListId: string
): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: types.set_busy,
                busy: true
            });

            fetch(`${getApiProtocol()}://${getApiHost()}:${getApiPort()}/scheme/app/${app}/${configId}`, {
                cache: 'no-cache',
                headers: {
                    'content-type': 'application/json',
                    'X-AUTH-TOKEN': token
                },
                method: 'GET',
                mode: 'cors',
                redirect: 'follow'
            }).then((response) => {
                if (!response.ok) {
                    throw response;
                }

                return response.json();
            }).then((result) => {
                const setRuleSet: ISetRuleSet = {
                    type: types.set_ruleset,
                    ruleSet: {
                        defaultConfigurations: result.defaultConfigurations,
                        userConfigurations: result.userConfigurations,
                        scheme: result.scheme
                    }
                };

                dispatch(setRuleSet);


                let enhancedConfiguration = null;
                if(get(getState(), 'ruleSet', null).defaultConfigurations.length > 0 && configId) {
                    enhancedConfiguration = getEnhancedConfigurationByApiConfiguration(
                        find(get(getState(), 'ruleSet').defaultConfigurations, {
                            id: parseInt(configId)
                        })
                    );
                } else if (get(getState(), 'ruleSet', null).defaultConfigurations.length === 0 && !configId) {
                    enhancedConfiguration = getEnhancedConfigurationByApiConfiguration({
                        id: null,
                        name: null,
                        salutation1: null,
                        firstName1: null,
                        lastName1: null,
                        birthday1: null,
                        city1: null,
                        zip1: null,
                        street1: null,
                        streetNumber1: null,
                        salutation2: null,
                        firstName2: null,
                        lastName2: null,
                        birthday2: null,
                        city2: null,
                        zip2: null,
                        street2: null,
                        streetNumber2: null,
                        propertyCity: null,
                        propertyPrice: null,
                        notary: 2,
                        conveyanceDuty: 3.5,
                        brokersCommission: 7.14,
                        conditionalContractProperty: null,
                        conditionalContractCredit: null,
                        conditionalContractBEG: null,
                        entities: [],
                        scheme: get(getState(), 'ruleSet').scheme,
                        ownPerformance: null,
                        incidentalCosts: {
                            engineeringWork: null,
                            engineeringWorkOwnPerformance: null,
                            siteFacilities: null,
                            outdoorMaterials: null,
                            outdoorWork: null,
                            outdoorWorkOwnPerformance: null,
                            mediaInterface: null,
                            changes: null
                        }
                    });
                } else {
                    enhancedConfiguration = getEnhancedConfigurationByApiConfiguration(
                        find(get(getState(), 'ruleSet').userConfigurations, {
                            id: parseInt(configId)
                        })
                    );
                }

                const configuration = assign({}, enhancedConfiguration, {
                    workflowStep: enhancedConfiguration.scheme.workflowSteps[0],
                    priceList: find(enhancedConfiguration.scheme.priceLists, {
                        id: priceListId
                    })
                });

                const setConfiguration: ISetConfiguration = {
                    type: types.set_configuration,
                    configuration
                };

                dispatch(setConfiguration);
                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                resolve();
            }).catch((response: Response) => {
                if (response.status === 401) {
                    dispatch(timeout());
                }

                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                reject();
            });
        });
    }
}
