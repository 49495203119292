import * as React from 'react';
import { find, filter, forEach, indexOf } from 'lodash';
import {Collapse, Row, Col, List, Button, Icon, Popover} from 'antd';
import IConfiguration from '../model/IConfiguration';
import { getOverallPrice, getPriceOfEntity, getVisuals, isEachStepFulfilled } from '../util/configuration';
import { format } from '../util/price';
import IEntity from '../model/IEntity';
import Slider from 'react-slick';
import { getApiHost, getApiPort, getApiProtocol } from '../util/env';

import '../../node_modules/slick-carousel/slick/slick.less';
import '../../node_modules/slick-carousel/slick/slick-theme.less';
import IWorkflowStep from "../model/IWorkflowStep";
import * as configuration from "../util/configuration";

export interface IProps {
    configuration: IConfiguration;
    onShowContractForm: (show: boolean) => void;
    onShowVisualsForm: (show: boolean) => void;
    onShowTemplateForm: (show: boolean) => void;
}

class Summary extends React.Component<IProps, {}> {
    protected slider: Slider;
    protected nextStep: IWorkflowStep;
    protected previousStep: IWorkflowStep;
    protected isCurrentStepFulfilled: boolean;
    protected unfullFilledSteps: any;

    public render() {
        try {
            this.nextStep = configuration.getNextStep(this.props.configuration);
        } catch (e) {
            this.nextStep = null;
        }

        try {
            this.previousStep = configuration.getPreviousStep(this.props.configuration);
        } catch (e) {
            this.previousStep = null;
        }

        this.isCurrentStepFulfilled = configuration.isStepFulfilled(
            this.props.configuration,
            this.props.configuration.workflowStep
        );

        this.unfullFilledSteps = configuration.getUnfulfilledFullSteps(
            this.props.configuration
        ).map((step) => {
            return <div key={step.name}>{step.name}</div>;
        });

        const entity = this.getReferencedByPriceListEntity();

        return (
            <Collapse style={{
                marginBottom: '15px',
            }}>
                <Collapse.Panel
                    key={`summary`}
                    header={this.renderHeader()}
                >
                    <Row gutter={32}>
                        <Col xs={24} sm={12} key="summary-visualization">
                            {this.renderVisuals()}
                        </Col>
                        <Col xs={24} sm={12} key="summary-details">
                            {this.renderDetails(entity)}
                        </Col>
                    </Row>
                </Collapse.Panel>
            </Collapse>
        );
    }

    protected renderVisuals() {
        const visuals = getVisuals(this.props.configuration);
        let groups: string[] = [];
        let renderVisuals: any[] = [];

        forEach(visuals, (file) => {
            if(indexOf(groups, file.g) === -1) {
                groups.push(file.g);
            }
        });
        groups.sort();

        forEach(groups, (group) => {
            if(!group) {
                return;
            }
            let currentGroupVisuals = filter(visuals, { 'g': group });
            if(group == '1') {
                let nextGroup = filter(visuals, { 'g': '2' });
                if(nextGroup.length !== 0) {
                    return;
                }
            }
            forEach(currentGroupVisuals, (visual) => {
                renderVisuals.push(
                    <div key={`visual-${visual.id}`} className="summary-image">
                        <img
                            src={`${getApiProtocol()}://${getApiHost()}:${getApiPort()}/uploads/${visual.f}`}/>
                    </div>
                );
            });
        });

        return (
            <div style={{
                paddingBottom: '20px'
            }}>
                <Slider key={(new Date()).getTime().toString()} fade={true} dots={true} arrows={false} ref={(slider) => {
                    if (slider) {
                        this.slider = slider;
                    }
                }}>
                    {renderVisuals}
                </Slider>
                {visuals.length > 0 && (
                    <Button style={{float: 'left'}} onClick={() => {
                        if (this.slider) {
                            this.slider.slickPrev();
                        }
                    }}>
                        <Icon type="arrow-left"/>
                    </Button>
                )}
                {visuals.length > 0 && (
                    <Button style={{float: 'right'}} onClick={() => {
                        if (this.slider) {
                            this.slider.slickNext();
                        }
                    }}>
                        <Icon type="arrow-right"/>
                    </Button>
                )}
            </div>
        );
    }

    protected renderDetails(entity: IEntity) {
        const priceList = (!!entity ? [entity] : []).concat(
            filter(this.props.configuration.entities, (item) => {
                return !!entity ? item.id !== entity.id : true;
            })
        );

        return (
            <span>
                {!!entity && (
                    <h1>{entity.name}</h1>
                )}
                <List
                    dataSource={priceList}
                    renderItem={(item: IEntity) => {
                        return (
                            <List.Item>
                                <List.Item.Meta
                                    title={item.name}
                                />
                                <span style={{float: 'right'}}>
                                    {format(getPriceOfEntity(this.props.configuration, item).price)}
                                </span>
                            </List.Item>
                        );
                    }}
                />
                {!!entity && (
                    <h2 style={{float: 'right'}}>
                        {format(getOverallPrice(this.props.configuration))}
                    </h2>
                )}
            </span>
        );
    }

    protected renderHeader() {
        const isComplete = isEachStepFulfilled(this.props.configuration);
        return (
            <span>
                <strong>
                    <span>{format(getOverallPrice(this.props.configuration))}</span>
                </strong>
                {this.unfullFilledSteps.length > 0 &&
                    <Popover
                        placement="bottomRight"
                        content={this.unfullFilledSteps}
                        title="Fehlende Schritte"
                    >
                        <Button
                            size="small"
                            style={{float: 'right', marginRight: '10px'}}
                            type="primary"
                            disabled={!isComplete}
                            onClick={() => {
                                this.props.onShowVisualsForm(true);
                            }}
                        >Visualisierung teilen</Button>
                    </Popover>
                }
                {this.unfullFilledSteps.length === 0 &&
                    <Button
                        size="small"
                        style={{float: 'right', marginRight: '10px'}}
                        type="primary"
                        disabled={!isComplete}
                        onClick={() => {
                            this.props.onShowVisualsForm(true);
                        }}
                    >Visualisierung teilen</Button>
                }
                {this.unfullFilledSteps.length > 0 &&
                    <Popover
                        placement="bottomRight"
                        content={this.unfullFilledSteps}
                        title="Fehlende Schritte"
                    >
                        <Button
                            size="small"
                            style={{float: 'right', marginRight: '10px'}}
                            type="primary"
                            disabled={!isComplete}
                            onClick={() => {
                                this.props.onShowContractForm(true);
                            }}
                        >PDF</Button>
                    </Popover>
                }
                {this.unfullFilledSteps.length === 0 &&
                    <Button
                        size="small"
                        style={{float: 'right', marginRight: '10px'}}
                        type="primary"
                        disabled={!isComplete}
                        onClick={() => {
                            this.props.onShowContractForm(true);
                        }}
                    >PDF</Button>
                }
                {this.unfullFilledSteps.length > 0 &&
                    <Popover
                        placement="bottomRight"
                        content={this.unfullFilledSteps}
                        title="Fehlende Schritte"
                    >
                        <Button
                            size="small"
                            style={{float: 'right', marginRight: '10px'}}
                            disabled={!isComplete}
                            onClick={() => {
                                this.props.onShowTemplateForm(true);
                            }}
                        >speichern</Button>
                    </Popover>
                }
                {this.unfullFilledSteps.length === 0 &&
                    <Button
                        size="small"
                        style={{float: 'right', marginRight: '10px'}}
                        disabled={!isComplete}
                        onClick={() => {
                            this.props.onShowTemplateForm(true);
                        }}
                    >speichern</Button>
                }
            </span>
        );
    }

    protected getReferencedByPriceListEntity(): IEntity {
        return find(this.props.configuration.entities, (item) => {
            return item.entityType.id === this.props.configuration.priceList.relatedEntityType.id;
        }) || null;
    }
}

export default Summary;
