import * as React from 'react';

class Unloader extends React.Component<{}, {}> {
    constructor(props: {}, context: {}) {
        super(props, context);

        this.onUnload = this.onUnload.bind(this);
    }

    onUnload(event : any) {
        const confirmationMessage = 'Wollen Sie die Seite wirklich verlassen? Ungespeicherte Änderungen gehen dabei verloren.';
        event.returnValue = confirmationMessage;
        return confirmationMessage;
    }

    componentWillMount() {
        window.addEventListener("beforeunload", this.onUnload);
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onUnload);
    }

    public render() {
        return <div></div>;
    }
}

export default Unloader;