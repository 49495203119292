import { ThunkAction } from 'redux-thunk';
import { types } from '../action/constants';
import IShowSettingsForm from '../action/IShowSettingsForm';

export default function showSettingsForm(show: boolean): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const showSettingsForm: IShowSettingsForm = {
                type: types.show_settings_form,
                show
            };

            dispatch(showSettingsForm);

            resolve();
        });
    }
}