import { ThunkAction } from 'redux-thunk';
import { types } from '../action/constants';
import ISetRuleSet from '../action/ISetRuleSet';

export default function resetRuleSet(): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const setRuleSet: ISetRuleSet = {
                type: types.set_ruleset,
                ruleSet: null
            };

            dispatch(setRuleSet);

            resolve();
        });
    }
}