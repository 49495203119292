import { ThunkAction } from 'redux-thunk';
import { types } from '../action/constants';
import timeout from './timeout';
import ISetRuleSetList from '../action/ISetRuleSetList';
import { getApiHost, getApiPort, getApiProtocol } from '../util/env';

export default function getRuleSetList(token: string, app: string): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: types.set_busy,
                busy: true
            });

            fetch(`${getApiProtocol()}://${getApiHost()}:${getApiPort()}/scheme/app/list/${app}`, {
                cache: 'no-cache',
                headers: {
                    'content-type': 'application/json',
                    'X-AUTH-TOKEN': token
                },
                method: 'GET',
                mode: 'cors',
                redirect: 'follow'
            }).then((response) => {
                if (!response.ok) {
                    throw response;
                }

                return response.json();
            }).then((result) => {
                const setRuleSetList: ISetRuleSetList = {
                    type: types.set_rulesetlist,
                    ruleSetList: {
                        defaultConfigurations: result.defaultConfigurations,
                        userConfigurations: result.userConfigurations,
                        scheme: result.scheme
                    }
                };

                dispatch(setRuleSetList);
                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                resolve();
            }).catch((response: Response) => {
                if (response.status === 401) {
                    dispatch(timeout());
                }

                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                reject();
            });
        });
    }
}