import { assign } from 'lodash';
import IState from '../state/IState';
import { actions, types } from '../action/constants';

export default function reduce(state: IState, action: actions): IState {
    switch (action.type) {
        case types.set_busy:
            return assign({}, state, {
                busy: action.busy
            });
        case types.set_auth:
            return assign({}, state, {
                auth: action.auth
            });
        case types.set_configuration:
            return assign({}, state, {
                configuration: action.configuration
            });
        case types.set_ruleset:
            return assign({}, state, {
                ruleSet: action.ruleSet
            });
        case types.set_rulesetlist:
            return assign({}, state, {
                ruleSetList: action.ruleSetList
            });
        case types.show_create_form:
            return assign({}, state, {
                showCreateForm: action.show
            });
        case types.show_load_form:
            return assign({}, state, {
                showLoadForm: action.show
            });
        case types.show_settings_form:
            return assign({}, state, {
                showSettingsForm: action.show
            });
        case types.show_contract_form:
            return assign({}, state, {
                showContractForm: action.show
            });
        case types.show_visuals_form:
            return assign({}, state, {
                showVisualsForm: action.show
            });
        case types.show_template_form:
            return assign({}, state, {
                showTemplateForm: action.show
            });
        default:
            return state;
    }
};