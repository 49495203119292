import { ThunkAction } from 'redux-thunk';
import ISetAuth from '../action/ISetAuth';
import { types } from '../action/constants';
import ISetConfiguration from '../action/ISetConfiguration';

export default function logout(): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const setAuth: ISetAuth = {
                type: types.set_auth,
                auth: null
            };

            dispatch(setAuth);

            const setConfiguration: ISetConfiguration = {
                type: types.set_configuration,
                configuration: null
            };

            dispatch(setConfiguration);
        });
    }
}