declare const API_HOST: string;
declare const API_PORT: string;
declare const API_PROTOCOL: string;
declare const VISUALS_FRONTEND: string;

export function getApiHost(): string {
    return API_HOST || '127.0.0.1';
};

export function getApiPort(): string {
    return API_PORT || '8888';
};

export function getApiProtocol(): string {
    return API_PROTOCOL || 'http';
};

export function getVisualsFrontend(): string {
    return VISUALS_FRONTEND || 'http://localhost:4002';
};