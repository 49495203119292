import * as React from 'react';

class Content extends React.Component<{}, {}> {
    public render() {
        return (
            <div className="main-container">
                <div className="main wrapper clearfix">
                    <div className="section group">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default Content;